import React from 'react';
import styled from 'styled-components';

const PricingCards = () => {
  return (
    <PricingWrapper>
      <Titulo>Preços</Titulo>
      <Container>
        <Row>
          <Card>
            <CardTitle>Mensal</CardTitle>
            <Price>R$139,90</Price>
            <Divider />
            <FeatureList>
              {/* Adicione recursos */}
            </FeatureList>
          </Card>
          <Card>
            <CardTitle>Familiar</CardTitle>
            <Price>R$119,90</Price>
            <Divider />
            <FeatureList>
              {/* Adicione recursos */}
            </FeatureList>
          </Card>
          <Card>
            <CardTitle>Plano Light de 12h ás 15h</CardTitle>
            <Price>R$139,90</Price>
            <Divider />
            <FeatureList>
              {/* Adicione recursos */}
            </FeatureList>
          </Card>
        </Row>
        <Row>
          <Card>
            <CardTitle>Trimestral</CardTitle>
            <Price>R$124,90</Price>
            <Divider />
            <FeatureList>
              {/* Adicione recursos */}
            </FeatureList>
          </Card>
          <Card>
            <CardTitle>Semestral</CardTitle>
            <Price>R$109,90</Price>
            <Divider />
            <FeatureList>
              {/* Adicione recursos */}
            </FeatureList>
          </Card>
          <Card>
            <CardTitle>Prime Anual</CardTitle>
            <Price>R$109,90</Price>
            <Divider />
            <FeatureList>
              {/* Adicione recursos */}
            </FeatureList>
          </Card>
        </Row>
      </Container>
    </PricingWrapper>
  );
};

export default PricingCards;

const PricingWrapper = styled.section`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #151515;
`;

const Container = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espaçamento entre as linhas */
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px; /* Espaçamento entre os cartões */

  @media (max-width: 768px) {
    flex-direction: column; /* Coloca os cartões em coluna no mobile */
    align-items: center;
  }
`;

const Titulo = styled.h1`
  color: #fff;
  font-size: 36px;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

const Card = styled.div`
  background-color: #151515;
  border-radius: 10px;
  border: solid #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 305px;
  color: #fff;

  @media (max-width: 768px) {
    width: 300px;
  }
`;

const CardTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Bebas Neue", sans-serif;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const Price = styled.h2`
  font-size: 48px;
  margin-bottom: 10px;
  color: #ffd700;
  font-family: "Bebas Neue", sans-serif;

  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 5px;
  }
`;

const Divider = styled.div`
  width: 50px;
  height: 4px;
  background: #fff;
  margin: 0 auto 20px auto;
  border-radius: 2px;

  @media (max-width: 768px) {
    margin: 0 auto 10px auto;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
`;
